import { Config } from "@/core/config/tables";

const configSLM: Config = {
  readingHeaders: [
    {
      name: "item",
      key: "item",
      sortable: true
    },
    {
      name: "description",
      key: "description",
      sortable: true
    },
    {
      name: "unit",
      key: "units",
      sortable: true
    },
    {
      name: "status",
      key: "status",
      sortable: true
    },
    {
      name: "date",
      key: "date",
      sortable: true
    },
    {
      name: "order",
      key: "doubleOrder",
      sortable: false
    },
  ],
  readingSort: "date",
  orderHeaders: [
    {
      name: "order",
      key: "code",
      sortable: false
    },
    {
      name: "hospital",
      key: "hospital",
      sortable: false
    },
    {
      name: "center",
      key: "center",
      sortable: false
    },
    {
      name: "criticality",
      key: "criticality",
      sortable: false
    },
    {
      name: "orderDate",
      key: "date",
      sortable: false
    },
    {
      name: "status",
      key: "status",
      sortable: false
    }
  ],
  lineHeaders: [
    {
      name: "Código Logaritme",
      key: "logaritme",
      sortable: true
    },
    {
      name: "Código SAP",
      key: "sap",
      sortable: true
    },
    {
      name: "Descripción",
      key: "description",
      sortable: true
    },
    {
      name: "Cantidad a reponer",
      key: "quantity",
      sortable: true
    },
    {
      name: "Tipo ubicación",
      key: "location",
      sortable: true
    }
  ],
  lineSort: "logaritme",
  defaultStatuses: ["Suministrado", "Pte Suministro"],
  supplyStatus: "Pte Suministro",
  itemHeaders: [
    {
      name: "item",
      key: "item",
      sortable: true
    },
    {
      name: "sap",
      key: "sap",
      sortable: true
    },
    {
      name: "shortDescription",
      key: "shortDescription",
      sortable: true
    },
    {
      name: "maximumQuantity",
      key: "maxQuantity",
      sortable: true
    },
    {
      name: "location",
      key: "location",
      sortable: true
    },
    {
      name: "measureUnit",
      key: "units",
      sortable: true
    },
  ],
  itemSort: "item",
  host: "https://back.aleuportal.com",
  util: "SLMUtil",
  countries: ["es", "ca"],
  languages: {
    es: {
      flag: "media/flags/spain.svg",
      name: "spanish"
    },
    ca: {
      flag: "media/flags/catalonia.svg",
      name: "catalan"
    }
  },
  defaultLanguage: "es",
  pages: [
    {
      heading: "lastReadings",
      route: "/ultimas-lecturas",
      svgIcon: "media/icons/duotune/art/art002.svg",
      fontIcon: "bi-app-indicator"
    },
    {
      heading: "lastOrders",
      route: "/ultimos-pedidos",
      svgIcon: "media/icons/duotune/art/art002.svg",
      fontIcon: "bi-app-indicator"
    },
    {
      heading: "search",
      route: "/search",
      svgIcon: "media/icons/duotune/art/art002.svg",
      fontIcon: "bi-app-indicator"
    },
    {
      heading: "info",
      route: "/info",
      svgIcon: "media/icons/duotune/art/art002.svg",
      fontIcon: "bi-app-indicator"
    }
  ],
  logo: "logo.png",
  refresh: 100000
};

export default configSLM;
