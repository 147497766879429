import { Config } from "@/core/config/tables";

const configSB: Config = {
  readingHeaders: [
    {
      name: "item",
      key: "item",
      sortable: true
    },
    {
      name: "sap",
      key: "sap",
      sortable: true
    },
    {
      name: "description",
      key: "description",
      sortable: true
    },
    {
      name: "date",
      key: "date",
      sortable: true
    },
    {
      name: "type",
      key: "type",
      sortable: true
    }
  ],
  readingSort: "date",
  orderHeaders: [
    {
      name: "order",
      key: "code",
      sortable: false
    },
    {
      name: "hospital",
      key: "hospital",
      sortable: false
    },
    {
      name: "center",
      key: "center",
      sortable: false
    },
    {
      name: "criticality",
      key: "criticality",
      sortable: false
    },
    {
      name: "orderDate",
      key: "date",
      sortable: false
    },
    {
      name: "status",
      key: "status",
      sortable: false
    }
  ],
  lineHeaders: [
    {
      name: "item",
      key: "item",
      sortable: true
    },
    {
      name: "description",
      key: "description",
      sortable: true
    },
    {
      name: "orderedQuantity",
      key: "ordQuantity",
      sortable: true
    },
    {
      name: "agreedQuantity",
      key: "agrQuantity",
      sortable: true
    },
    {
      name: "status",
      key: "status",
      sortable: true
    },
    {
      name: "unit",
      key: "units",
      sortable: true
    },
    {
      name: "suppliedQuantity",
      key: "sumQuantity",
      sortable: true
    }
  ],
  lineSort: "item",
  defaultStatuses: ["Suministrado", "Pte Suministro"],
  supplyStatus: "Pte Suministro",
  itemHeaders: [
    {
      name: "item",
      key: "item",
      sortable: true
    },
    {
      name: "sap",
      key: "sap",
      sortable: true
    },
    {
      name: "shortDescription",
      key: "shortDescription",
      sortable: true
    },
    {
      name: "maximumQuantity",
      key: "maxQuantity",
      sortable: true
    },
    {
      name: "location",
      key: "location",
      sortable: true
    },
    {
      name: "measureUnit",
      key: "units",
      sortable: true
    },
  ],
  itemSort: "item",
  host: "https://back.aleuportal.com",
  util: "SBUtil",
  countries: ["es", "ca"],
  languages: {
    es: {
      flag: "media/flags/spain.svg",
      name: "spanish"
    },
    ca: {
      flag: "media/flags/catalonia.svg",
      name: "catalan"
    }
  },
  defaultLanguage: "es",
  pages: [
    {
      heading: "lastReadings",
      route: "/ultimas-lecturas",
      svgIcon: "media/icons/duotune/art/art002.svg",
      fontIcon: "bi-app-indicator",
    },
    {
      heading: "lastOrders",
      route: "/ultimos-pedidos",
      svgIcon: "media/icons/duotune/art/art002.svg",
      fontIcon: "bi-app-indicator",
    },
    {
      heading: "search",
      route: "/search",
      svgIcon: "media/icons/duotune/art/art002.svg",
      fontIcon: "bi-app-indicator",
    },
    {
      heading: "supply",
      route: "/supply",
      svgIcon: "media/icons/duotune/art/art002.svg",
      fontIcon: "bi-app-indicator",
    },
    {
      heading: "info",
      route: "/info",
      svgIcon: "media/icons/duotune/art/art002.svg",
      fontIcon: "bi-app-indicator",
    },
  ],
  logo: "logo.png",
  refresh: 100000
};

export default configSB;
