import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Reading, Line, Item} from "@/core/config/tables";

export interface ChangeLine {
  id: number;
  sumQuantity: number;
  status: string;
}

export interface SupplyInfo {
  errors: Array<string>;
  orderLine: Line;
  reading: Reading;
  item: Item;
  ip: string;
  client: string;
}

@Module
export default class SupplyModule extends VuexModule implements SupplyInfo {
  errors = [];
  orderLine = {} as Line;
  reading = {} as Reading;
  item = {} as Item;
  changes = Array<ChangeLine>();
  ip = "";
  client = "SLM";

  @Mutation
  [Mutations.SET_READING](reading) {
    this.reading = reading;
  }

  @Action
  [Actions.UPDATE_ITEM](item) {
    this.context.commit(Mutations.SET_ITEM, item);
  }

  @Mutation
  [Mutations.SET_ITEM](item) {
    this.item = item;
  }

  @Action
  [Actions.UPDATE_READING](reading) {
    this.context.commit(Mutations.SET_READING, reading);
  }

  @Mutation
  [Mutations.SET_ORDER_LINE](orderLine) {
    this.orderLine = orderLine;
  }

  @Action
  [Actions.UPDATE_ORDER_LINE](orderLine) {
    this.context.commit(Mutations.SET_ORDER_LINE, orderLine);
  }
  
  @Mutation
  [Mutations.ADD_ORDER_LINE_CHANGE](changeLine) {
    this.changes.push(changeLine);
  }

  @Action
  [Actions.NEW_ORDER_LINE_CHANGE](changeLine) {
    this.context.commit(Mutations.ADD_ORDER_LINE_CHANGE, changeLine);
  }

  @Mutation
  [Mutations.RESET_CHANGES]() {
    this.changes = Array<ChangeLine>();
  }

  @Action
  [Actions.CLEAN_CHANGES]() {
    this.context.commit(Mutations.RESET_CHANGES);
  }

  @Mutation
  [Mutations.SET_IP](ip) {
    this.ip = ip;
  }

  @Action
  [Actions.UPDATE_IP](ip) {
    this.context.commit(Mutations.SET_IP, ip);
  }

  @Mutation
  [Mutations.SET_CLIENT](client) {
    this.client = client;
  }

  @Action
  [Actions.UPDATE_CLIENT](client) {
    this.context.commit(Mutations.SET_CLIENT, client);
  }

  /**
   * Get current selected reading
   * @returns Reading
   */
   get getReading(): Reading {
    return this.reading;
  }

  /**
   * Get current selected item
   * @returns Item
   */
   get getItem(): Item {
    return this.item;
  }

  /**
   * Get current selected order line
   * @returns Line
   */
  get getOrderLine(): Line {
    return this.orderLine;
  }

  /**
   * Get all changed order lines
   * @returns Array<ChangeLine>
   */
   get getChangeLines(): Array<ChangeLine> {
    return this.changes;
  }

  /**
   * Get last changed order line
   * @returns ChangeLine
   */
   get getLastLineChange(): ChangeLine {
    return this.changes[this.changes.length-1];
  }

  /**
   * Get current supply box ip
   * @returns string
   */
    get getIP(): string {
    return this.ip;
  }

  /**
   * Get current client
   * @returns string
   */
   get getClient(): string {
    return this.client;
  }
}