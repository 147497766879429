import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Info } from "@/core/config/tables";

interface SupplyBoxInfo {
  info: Info
}

@Module
export default class InfoModule extends VuexModule implements SupplyBoxInfo {
  info = {center: "Código centro de coste", hospital: "Nombre hospital", description: "Descripción centro de coste"} as Info;

  @Mutation
  [Mutations.SET_INFO](info) {
    this.info = info;
  }

  @Action
  [Actions.UPDATE_INFO](info) {
    this.context.commit(Mutations.SET_INFO, info);
  }
  
  /**
   * Get current supply box info
   * @returns Info
   */
  get getSupplyBoxInfo(): Info {
    return this.info;
  }

  /**
   * Get current supply box center
   * @returns string
   */
   get getCenter(): string {
    return this.info.center;
  }

 /**
   * Get current supply box hospital
   * @returns string
   */
  get getHospital(): string {
    return this.info.hospital;
  }
}