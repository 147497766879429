enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  UPDATE_ORDER_LINE = "updateOrderLine",
  NEW_ORDER_LINE_CHANGE = "newOrderLineChange",
  CLEAN_CHANGES = "cleanChanges",
  UPDATE_INFO = "updateInfo",
  UPDATE_IP = "updateIP",
  UPDATE_READING = "updateReading",
  UPDATE_ITEM = "updateItem",
  UPDATE_CLIENT = "updateClient"
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_ORDER_LINE = "setOrderLine",
  ADD_ORDER_LINE_CHANGE = "addOrderLineChange",
  RESET_CHANGES = "resetChanges",
  SET_INFO = "setInfo",
  SET_IP = "setIP",
  SET_READING = "setReading",
  SET_ITEM = "setItem",
  SET_CLIENT = "setClient"
}

export { Actions, Mutations };
