const ID_TOKEN_KEY = "JWT_TOKEN_SESSION" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  const token_cookie = ID_TOKEN_KEY + "=";
    const cookie = document.cookie.split(';');
    for(let i=0; i < cookie.length; i++) {
        let c = cookie[i];
        while (c.charAt(0)==' ') c = c.substring(1, c.length);
        if (c.indexOf(token_cookie) == 0) return c.substring(token_cookie.length, c.length);
    }
    return null;
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  //window.localStorage.setItem(ID_TOKEN_KEY, token);
  document.cookie = ID_TOKEN_KEY + "=" + token + "; Path=/; ";
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  //window.localStorage.removeItem(ID_TOKEN_KEY);
  document.cookie = ID_TOKEN_KEY +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export default { getToken, saveToken, destroyToken };
