import configSB from "@/core/config/SB/SBConfig";
import configSLM from "@/core/config/SLM/SLMConfig";
import store from "@/store";

let config = configSB;
const client = store.getters.getClient;
if(client === "SLM") {
  config = configSLM;
}

export default config;