import { createI18n } from "vue-i18n";
import config from "@/core/config/UtilConfig";

const messages = {
  es: {
    lastReadings: "Últimas lecturas",
    lastOrders: "Últimos pedidos",
    search: "Buscar",
    supply: "Suministrar",
    info: "Información",
    language: "Idioma",
    item: "Artículo",
    sap: "SAP",
    description: "Descripción",
    date: "Fecha",
    type: "Tipo",
    quantity: "Cantidad",
    orderedQuantity: "Cantidad pedida",
    agreedQuantity: "Cantidad pactada",
    status: "Estado",
    unit: "Unidad",
    suppliedQuantity: "Cantidad suministrada",
    order: "Pedido",
    hospital: "Hospital",
    center: "Centro de consumo",
    criticality: "Criticidad",
    orderDate: "Fecha pedido",
    supplied: "Suministrado",
    pending: "Pendiente",
    searchItems: "Buscar artículos",
    client: "Cliente",
    delivery: "Entrega",
    name: "Nombre",
    shortDescription: "Descripción corta",
    longDescription: "Descripción larga",
    maximumQuantity: "Cantidad máxima",
    location: "Localización",
    period: "Período",
    measureUnit: "Unidad medida",
    tag: "Etiqueta",
    signIn: "Inicia sesión",
    user: "Usuario",
    password: "Contraseña",
    logIn: "Entrar",
    confirmSupply: "Confirmar suministro",
    tryAgain: "Usuario y/o contraseña erróneos",
    refreshing: "Actualizando...",
    yes: "Sí",
    no: "No",
    doYouWantToChangeOrder: "Los cambios introducidos se perderán si no se confirma el suministro. ¿Deseas cambiar de pedido?",
    doYouWantToChangeScreen: "Los cambios introducidos se perderán si no se confirma el suministro. ¿Deseas cambiar de pantalla?",
    additionalInfo: "Información adicional",
    logOut: "Salir",
    spanish: "Español",
    catalan: "Catalán",
    accept: "Aceptar",
    cancel: "Cancelar",
    pleaseWait: "Por favor, espera...",
    quantityBiggerThanOrdered: "La cantidad suministrada es mayor que la pedida, revisa los datos y vuelve a confirmar si es correcto",
    quantityRequired: "La cantidad es requerida",
    changeQuantity: "Cambiar cantidad",
    centerData: "Datos del centro de consumo",
    code: "Código",
    orderSchedule: "Calendario de pedidos",
    stockDays: "Días y horas previstas de reposición",
    edit: "Editar",
    editInfo: "Editar información",
    centerRequired: "El código de centro es requerido",
    hospitalRequired: "El hospital es requerido",
    monday: "Lunes",
    tuesday: "Martes",
    wednesday: "Miércoles",
    thursday: "Jueves",
    friday: "Viernes",
    refreshPage: "Recargar",
    select: "Selecciona"
  },
  ca: {
    lastReadings: "Últimes lectures",
    lastOrders: "Últimes comandes",
    search: "Buscar",
    supply: "Subministrar",
    info: "Informació",
    language: "Idioma",
    item: "Article",
    sap: "SAP",
    description: "Descripció",
    date: "Data",
    type: "Tipus",
    quantity: "Quantitat",
    orderedQuantity: "Quantitat demanada",
    agreedQuantity: "Quantitat pactada",
    status: "Estat",
    unit: "Unitat",
    suppliedQuantity: "Quantitat subministrada",
    order: "Comanda",
    hospital: "Hospital",
    center: "Centre de consum",
    criticality: "Criticitat",
    orderDate: "Data comanda",
    supplied: "Subministrat",
    pending: "Pendent",
    searchItems: "Buscar articles",
    client: "Client",
    delivery: "Entrega",
    name: "Nom",
    shortDescription: "Descripció curta",
    longDescription: "Descripció llarga",
    maximumQuantity: "Quantitat màxima",
    location: "Localització",
    period: "Període",
    measureUnit: "Unitat mesura",
    tag: "Etiqueta",
    signIn: "Inicia sessió",
    user: "Usuari",
    password: "Contrasenya",
    logIn: "Entrar",
    confirmSupply: "Confirmar subministrament",
    tryAgain: "Usuari i/o contrasenya erronis",
    refreshing: "Actualitzant...",
    yes: "Sí",
    no: "No",
    doYouWantToChangeOrder: "Els canvis introduïts es perdràn si no es confirma el subministrament. Desitges canviar de comanda?",
    doYouWantToChangeScreen: "Els canvis introduïts es perdràn si no es confirma el subministrament. Desitges canviar de pantalla?",
    additionalInfo: "Informació adicional",
    logOut: "Sortir",
    spanish: "Espanyol",
    catalan: "Català",
    accept: "Acceptar",
    cancel: "Cancel·lar",
    pleaseWait: "Si us plau, espera...",
    quantityBiggerThanOrdered: "La quantitat subministrada és major que la demanada, revisa les dades i torna a confirmar si és correcte",
    quantityRequired: "La quantitat és requerida",
    changeQuantity: "Canviar quantitat",
    centerData: "Dades del centre de consum",
    code: "Codi",
    orderSchedule: "Calendari de comandes",
    stockDays: "Dies i hores previstes de reposició",
    edit: "Editar",
    editInfo: "Editar informació",
    centerRequired: "El codi del centre és requerit",
    hospitalRequired: "L'hospital és requerit",
    monday: "Dilluns",
    tuesday: "Dimarts",
    wednesday: "Dimecres",
    thursday: "Dijous",
    friday: "Divendres",
    refreshPage: "Recarregar",
    select: "Selecciona"
  }
};

const i18n = createI18n({
  legacy: false,
  locale: config.defaultLanguage,
  globalInjection: true,
  messages,
});

export default i18n;
